.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 100px;
  object-fit: cover;
}
.home-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text01 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text01:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text02 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text02:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text03 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text03:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text04 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text05 {
  transition: 0.3s;
}
.home-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-container01 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-menu1 {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image01 {
  width: 100px;
  object-fit: cover;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon05 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text06 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text06:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text07 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text07:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text08 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text08:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text09 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text09:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text10 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text10:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text11 {
  transition: 0.3s;
}
.home-text11:hover {
  color: var(--dl-color-turquoise-default);
}
.home-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-container02 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-container03 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon07 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon07:hover {
  fill: var(--dl-color-purple-default);
}
.home-link1 {
  display: contents;
}
.home-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon09 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon09:hover {
  fill: var(--dl-color-pink-default);
}
.home-link2 {
  display: contents;
}
.home-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon11:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link3 {
  display: contents;
}
.home-container06 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon13 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon13:hover {
  fill: var(--dl-color-orange-default);
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text12 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-image02 {
  width: 320px;
  object-fit: cover;
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-clients {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-image03 {
  width: 100px;
  object-fit: cover;
}
.home-image04 {
  width: 100px;
  object-fit: cover;
}
.home-image05 {
  width: 100px;
  object-fit: cover;
}
.home-image06 {
  width: 100px;
  object-fit: cover;
}
.home-image07 {
  width: 100px;
  object-fit: cover;
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text17 {
  max-width: 500px;
  text-align: center;
}
.home-text18 {
  white-space: pre-wrap;
}
.home-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-container08 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container09 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-image08 {
  top: -22px;
  left: -65px;
  right: auto;
  width: 100px;
  bottom: auto;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.home-text19 {
  z-index: 100;
  max-width: 500px;
}
.home-container10 {
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container11 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-container12 {
  width: 350px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container13 {
  width: 300px;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
.home-achievements {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container14 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text22 {
  white-space: pre-wrap;
}
.home-container15 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container16 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container17 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container18 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-purple-100);
}
.home-image09 {
  width: 360px;
  margin-top: 100px;
  object-fit: cover;
}
.home-container19 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text27 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container20 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text29 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-image10 {
  width: 100%;
  height: 400px;
  margin-top: var(--dl-space-space-tripleunit);
  object-fit: cover;
}
.home-cta {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-turquoise-default);
}
.home-container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container22 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text31 {
  max-width: 500px;
}
.home-text32 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text33 {
  white-space: pre-wrap;
}
.home-container23 {
  width: 520px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image11 {
  top: -100px;
  left: auto;
  right: var(--dl-space-space-doubleunit);
  width: 232px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-image12 {
  top: auto;
  left: var(--dl-space-space-doubleunit);
  right: auto;
  width: 232px;
  bottom: -100px;
  position: absolute;
  object-fit: cover;
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image13 {
  object-fit: cover;
}
.home-container25 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.home-text34 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.home-text34:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text35 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.home-text35:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text36 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.home-text36:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text37 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.home-text37:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text38 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.home-text38:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text39 {
  transition: 0.3s;
  white-space: nowrap;
}
.home-text39:hover {
  color: var(--dl-color-turquoise-default);
}
.home-divider2 {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.home-container26 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text40 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.home-container27 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link4 {
  display: contents;
}
.home-container28 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon15 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon15:hover {
  fill: var(--dl-color-purple-default);
}
.home-link5 {
  display: contents;
}
.home-container29 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon17 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon17:hover {
  fill: var(--dl-color-pink-default);
}
.home-link6 {
  display: contents;
}
.home-container30 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon19 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon19:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link7 {
  display: contents;
}
.home-container31 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon21 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon21:hover {
  fill: var(--dl-color-orange-default);
}
@media(max-width: 991px) {
  .home-container01 {
    display: none;
  }
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-container07 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-text12 {
    text-align: center;
  }
  .home-container08 {
    flex-direction: column;
  }
  .home-container10 {
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-container11 {
    align-items: center;
    margin-left: 0px;
  }
  .home-container13 {
    width: 350px;
  }
  .home-achievements {
    flex-direction: column;
  }
  .home-container15 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .home-feature1 {
    flex-direction: column;
  }
  .home-container19 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-container20 {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container23 {
    display: none;
  }
}
@media(max-width: 767px) {
  .home-menu {
    display: none;
  }
  .home-burger-menu {
    display: block;
  }
  .home-text12 {
    font-size: 50px;
    text-align: center;
  }
  .home-clients {
    justify-content: center;
  }
  .home-image03 {
    margin: var(--dl-space-space-unit);
  }
  .home-image04 {
    margin: var(--dl-space-space-unit);
  }
  .home-image05 {
    margin: var(--dl-space-space-unit);
  }
  .home-image06 {
    margin: var(--dl-space-space-unit);
  }
  .home-image07 {
    margin: var(--dl-space-space-unit);
  }
  .home-text16 {
    font-size: 40px;
  }
  .home-container09 {
    align-items: center;
  }
  .home-image08 {
    top: -2px;
    left: 21px;
    right: auto;
    width: 50px;
    bottom: auto;
  }
  .home-text19 {
    font-size: 40px;
    text-align: center;
  }
  .home-text21 {
    font-size: 40px;
  }
  .home-text28 {
    font-size: 40px;
  }
  .home-text30 {
    font-size: 40px;
  }
  .home-container25 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-container26 {
    flex-direction: column;
  }
  .home-text40 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-container01 {
    display: none;
  }
  .home-bot {
    width: 100%;
  }
  .home-text16 {
    text-align: center;
  }
  .home-text17 {
    text-align: center;
  }
  .home-container14 {
    align-items: center;
  }
  .home-text21 {
    text-align: center;
  }
  .home-text25 {
    text-align: center;
  }
  .home-container16 {
    flex-direction: column;
  }
  .home-container17 {
    flex-direction: column;
  }
}
